import invitations from './invitations'
import programs from './programs'

const data = [...invitations, ...programs,

  // List Incubator
  {
    path: '/mentor-portal/incubators',
    name: 'mentor-incubators',
    component: () => import('@/views/mentor-portal/incubators/ManageIncubators.vue'),
    meta: {
      pageTitle: 'Manage Incubator',
      breadcrumb: [
        {
          text: 'Mentor',
        },
        {
          text: 'Incubators',
          active: true,
        },
      ],
    },
  },
  // Incubator Manager
  {
    path: '/mentor-portal/incubator/:id/manage',
    name: 'mentor-incubator-manager',
    component: () => import('@/views/mentor-portal/incubators/incubator-manager/index.vue'),
    meta: {
      pageTitle: 'Manage Incubator',
      breadcrumb: [
        {
          text: 'Mentor',
        },
        {
          text: 'Incubator Manager',
          active: true,
        },
      ],
    },
  },
]

export default data
