import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import Ripple from 'vue-ripple-directive'
import {
  ButtonPlugin,
  BVConfigPlugin,
  FormPlugin,
  LayoutPlugin,
  ModalPlugin,
  OverlayPlugin,
  ToastPlugin,
  VBTooltipPlugin,
} from 'bootstrap-vue'
import { $themeConfig } from '../themeConfig'

Vue.component(FeatherIcon.name, FeatherIcon)
Vue.directive('ripple', Ripple)

Vue.use(BVConfigPlugin, {
  BOverlay: { spinnerVariant: 'primary', rounded: 'sm', variant: $themeConfig.layout.skin },
  BModal: { cancelVariant: 'flat-danger' },
})
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(OverlayPlugin)
Vue.use(LayoutPlugin)
Vue.use(VBTooltipPlugin)
Vue.use(FormPlugin)
Vue.use(ButtonPlugin)
