export default [
  {
    path: '/fundings/manage',
    name: 'manage-fundings',
    component: () => import('@/views/fundings/ManageFundings.vue'),
    meta: {
      pageTitle: 'Manage Fundings',
      breadcrumb: [
        {
          text: 'Fundings',
        },
        {
          text: 'Manage Fundings',
          active: true,
        },
      ],
    },
  },
  {
    path: '/fundings/add',
    name: 'add-fundings',
    component: () => import('@/views/fundings/AddFundings.vue'),
    meta: {
      pageTitle: 'Add Funding',
      breadcrumb: [
        {
          text: 'Fundings',
        },
        {
          text: 'Add Funding',
          active: true,
        },
      ],
    },
  },
  {
    path: '/fundings/manager/:id',
    name: 'funding-manager',
    component: () => import('@/views/fundings/funding-manager/FundingManager.vue'),
    meta: {
      pageTitle: 'Funding Manager',
      breadcrumb: [
        {
          text: 'Fundings',
          to: '/fundings/manage',
        },
        {
          text: 'Funding Manager',
          active: true,
        },
      ],
    },
  },
  {
    path: '/fundings/trench',
    name: 'Trench-Review',
    component: () => import('@/views/fundings/funding-manager/components/trenchReview.vue'),
    meta: {
      pageTitle: 'Tench Review',
      breadcrumb: [
        {
          text: 'Trenches',
          to: '/fundings/trench',
        },
        {
          text: 'Trench review ',
          active: true,
        },
      ],
    },
  },
  {
    path: '/fundings/evalution',
    name: 'Evalution',
    component: () => import('@/views/fundings/funding-manager/components/evalution.vue'),
    meta: {
      pageTitle: 'Evalution',
      breadcrumb: [
        {
          text: 'Evalution',
          to: '/fundings/evalution',
        },
        {
          text: 'Evalution ',
          active: true,
        },
      ],
    },
  },
  {
    path: '/fundings/advisor/assigntrench',
    name: 'Assign Trench',
    component: () => import('@/views/fundings/funding-manager/components/assignTrench.vue'),
    meta: {
      pageTitle: 'Assign Trench',
      breadcrumb: [
        {
          text: 'Assign Trench',
          to: '/fundings/advisor/assigntrench',
        },
        {
          text: 'Assign Trench ',
          active: true,
        },
      ],
    },
  },
  {
    path: '/fundings/investor/viewtrenches',
    name: 'view Trenches',
    component: () => import('@/views/fundings/funding-manager/components/viewTrenches.vue'),
    meta: {
      pageTitle: 'View Trenches',
      breadcrumb: [
        {
          text: 'View Trenches',
          to: '/fundings/investor/viewtrenches',
        },
        {
          text: 'View Trenches ',
          active: true,
        },
      ],
    },
  },
  {
    path: '/fundings/investor/fundstransactions',
    name: 'Funds Transactions',
    component: () => import('@/views/fundings/funding-manager/components/fundsTransactions.vue'),
    meta: {
      pageTitle: 'Funds Transactions',
      breadcrumb: [
        {
          text: 'Funds Transactions',
          to: '/fundings/investor/fundstransactions',
        },
        {
          text: 'Funds Transactions',
          active: true,
        },
      ],
    },
  },
]
