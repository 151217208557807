export default [
  {
    path: '/startup-portal/programs',
    name: 'startup-program',
    component: () => import('@/views/startup-portal/pages/programs/Programs.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Programs',
      breadcrumb: [
        {
          text: 'Startup',
        },
        {
          text: 'Programs',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup-portal/programs/:id',
    name: 'startup-view-programs',
    component: () => import('@/views/incubator-portal/programs/pages/common/ViewPrograms.vue'),
    meta: {
      pageTitle: 'View Program',
      breadcrumb: [
        {
          text: 'Programs',
        },
        {
          text: 'View Program',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup-portal/programs/select/:id',
    name: 'program-enter',
    component: () => import('@/views/startup-portal/pages/programs/SelectOrganisation.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Select Organisation',
      breadcrumb: [
        {
          text: 'Startup',
        },
        {
          text: 'Programs',
        },
        {
          text: 'Select Organisation',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup-portal/programs/enter/:id/:aid',
    name: 'startup-programs',
    component: () => import('@/views/startup-portal/pages/programs/enter/index.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Program',
      breadcrumb: [
        {
          text: 'Programs',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup-portal/programs/enter/:id/:aid/:prid',
    name: 'progress-review',
    component: () => import('@/views/startup-portal/pages/programs/enter/ProgressReview.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Progress Review',
      breadcrumb: [
        {
          text: 'Programs',
        },
        {
          text: 'Progress Review',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/startup-portal/programs/milestone/:id/:aid/:mid',
  //   name: 'startup-milestone-review',
  //   component: () => import('@/views/startup-portal/pages/programs/enter/Milestone.vue'),
  //   meta: {
  //     contentClass: 'ecommerce-application',
  //     pageTitle: 'Milestone Reviews',
  //     breadcrumb: [
  //       {
  //         text: 'Programs',
  //       },
  //       {
  //         text: 'Milestone',
  //       },
  //       {
  //         text: 'Milestone Reviews',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  {
    path: '/startup-portal/milestone/:id/:mid/:aid?',
    name: 'startup-milestone-stages',
    component: () => import('@/views/startup-portal/pages/programs/enter/milestone-manager/MilestoneStages.vue'),
    meta: {
      pageTitle: 'Milestone Stages',
      breadcrumb: [
        {
          text: 'Programs',
        },
        {
          text: 'Milestone Stages',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup-portal/milestone/review/:id/:sid/:aid?',
    name: 'startup-milestone-review',
    component: () => import('@/views/startup-portal/pages/programs/enter/milestone-manager/StageReviewQuestions.vue'),
    meta: {
      pageTitle: 'Milestone Stages',
      breadcrumb: [
        {
          text: 'Programs',
        },
        {
          text: 'Milestone Stages',
        },
        {
          text: 'Review',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup-portal/programs/progress/details/:id',
    name: 'program-progress-details',
    component: () => import('@/views/startup-portal/pages/programs/enter/ProgressDetails.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Program Process',
      breadcrumb: [
        {
          text: 'Programs',
        },
        {
          text: 'Program Process',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup-portal/programs/review/:pid/:apid/:aid?',
    name: 'startup-review-questions',
    component: () => import('@/views/incubator-portal/programs/pages/startups/program-manager/review-manager/ReviewQuestion.vue'),
    meta: {
      pageTitle: 'Review Questions',
    },
  },
  {
    // APID = Review ID, AID = Participant ID, RID = Reviewer/Partner ID
    path: '/startup-portal/programs/review/evaluations/:pid/:apid/:aid',
    name: 'startup-review-evaluations',
    component: () => import('@/views/incubator-portal/programs/pages/startups/program-manager/review-manager/ViewEvaluation.vue'),
    meta: {
      pageTitle: 'Review Evaluations',
      breadcrumb: [
        {
          text: 'Startup',
        },
        {
          text: 'Reviews',
        },
        {
          text: 'Evaluations',
          active: true,
        },
      ],
    },
  },
]
