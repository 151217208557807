import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: 'https://4rf1lach53.execute-api.ap-south-1.amazonaws.com/dev/programs/',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

Vue.prototype.$http = axiosIns

// Temporary: Avoid interceptors
Vue.prototype.$axios = axios

export default axiosIns
