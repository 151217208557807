export default [
  {
    path: '/startup-portal/apply-now',
    name: 'startup-apply-now',
    component: () => import('@/views/startup-portal/pages/apply-now/ApplyNow.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Apply Now',
      breadcrumb: [
        {
          text: 'Startup',
        },
        {
          text: 'Apply Now',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup-portal/application/:pid/:apid/:aid?',
    name: 'startup-application-question',
    component: () => import('@/views/incubator-portal/programs/pages/startups/program-manager/application-manager/ApplicationQuestion.vue'),
    meta: {
      pageTitle: 'Application Questions',
      breadcrumb: [
        {
          text: 'Startup',
        },
        {
          text: 'Apply Now',
          active: true,
        },
      ],
    },
  },
]
