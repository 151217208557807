export default [
  {
    path: '/campaigns',
    name: 'manage-campaigns',
    component: () => import('@/views/campaigns/pages/ManageCampaigns.vue'),
    meta: {
      pageTitle: 'Manage Campaigns',
      breadcrumb: [
        {
          text: 'Campaigns',
          active: true,
        },
      ],
    },
  },
  {
    path: '/campaigns/add',
    name: 'add-campaigns',
    component: () => import('@/views/campaigns/pages/AddCampaigns.vue'),
    meta: {
      pageTitle: 'Add Campaign',
      breadcrumb: [
        {
          text: 'Campaigns',
          to: { name: 'manage-campaigns' },
        },
        {
          text: 'Add Campaign',
          active: true,
        },
      ],
    },
  },
  {
    path: '/campaigns/:id',
    name: 'view-campaigns',
    component: () => import('@/views/campaigns/pages/AddCampaigns.vue'),
    meta: {
      pageTitle: 'Update Campaign',
      breadcrumb: [
        {
          text: 'Campaigns',
          to: { name: 'manage-campaigns' },
        },
        {
          text: 'Add Campaign',
          active: true,
        },
      ],
    },
  },
  {
    path: '/campaigns/:id/manage',
    name: 'campaign-manager',
    component: () => import('@/views/campaigns/pages/campaign-manager/index.vue'),
    meta: {
      pageTitle: 'Campaign Manager',
      breadcrumb: [
        {
          text: 'Campaigns',
          to: { name: 'manage-campaigns' },
        },
        {
          text: 'Campaign Manager',
          active: true,
        },
      ],
    },
  },
]
