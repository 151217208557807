export default [
  {
    path: '/partners',
    name: 'manage-partners',
    component: () => import('@/views/incubator-portal/partners/pages/ManagePartners.vue'),
    meta: {
      pageTitle: 'Manage Partners',
      breadcrumb: [
        {
          text: 'Partners',
          active: true,
        },
      ],
    },
  },
  {
    path: '/partners/add',
    name: 'add-partners',
    component: () => import('@/views/incubator-portal/partners/pages/AddPartners.vue'),
    meta: {
      pageTitle: 'Add Member',
      breadcrumb: [
        {
          text: 'Partners',
          to: { name: 'manage-partners' },
        },
        {
          text: 'Add Member',
          active: true,
        },
      ],
    },
  },
  {
    path: '/partners/:id',
    name: 'view-partners',
    component: () => import('@/views/incubator-portal/partners/pages/AddPartners.vue'),
    meta: {
      pageTitle: 'Update Partner',
      breadcrumb: [
        {
          text: 'Partners',
          to: { name: 'manage-partners' },
        },
        {
          text: 'Add Partner',
          active: true,
        },
      ],
    },
  },
  {
    path: '/partners/:id/manage',
    name: 'partner-manager',
    component: () => import('@/views/incubator-portal/partners/pages/partner-manager/index.vue'),
    meta: {
      pageTitle: 'Partner Manager',
      breadcrumb: [
        {
          text: 'Partners',
          to: { name: 'manage-partners' },
        },
        {
          text: 'Partner Manager',
          active: true,
        },
      ],
    },
  },
]
