export default [
  {
    path: '/finances/manage',
    name: 'manage-finances',
    component: () => import('@/views/finances/ManageFinances.vue'),
    meta: {
      pageTitle: 'Manage Finances',
      breadcrumb: [
        {
          text: 'Finances',
        },
        {
          text: 'Manage Finances',
          active: true,
        },
      ],
    },
  },
  {
    path: '/finances/addRevenueStream',
    name: 'add-revenue-stream',
    component: () => import('@/views/finances/AddRevenueStream.vue'),
    meta: {
      pageTitle: 'Add Revenue Stream',
      breadcrumb: [
        {
          text: 'Finances',
        },
        {
          text: 'Add Revenue Stream',
          active: true,
        },
      ],
    },
  },
  {
    path: '/finances/addExpenseHead',
    name: 'add-expense-head',
    component: () => import('@/views/finances/AddExpenseHead.vue'),
    meta: {
      pageTitle: 'Add Expense Head',
      breadcrumb: [
        {
          text: 'Finances',
        },
        {
          text: 'Add Expense Head',
          active: true,
        },
      ],
    },
  },
]
