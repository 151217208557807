import Vue from 'vue'
import VueApollo from 'vue-apollo'
// import { HttpLink } from 'apollo-link-http'
import { createApolloClient } from 'vue-cli-plugin-apollo/graphql-client'
import { setContext } from 'apollo-link-context'
import { Auth } from 'aws-amplify'
import { InMemoryCache } from 'apollo-cache-inmemory'

// Install the vue plugin
Vue.use(VueApollo)

// Name of the localStorage item
const AUTH_TOKEN = 'apollo-token'

// Http endpoint
const httpEndpoint = process.env.VUE_APP_GRAPHQL_HTTP || 'https://starworks-erp.hasura.app/v1/graphql'

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = await Auth.currentSession()
    .then(res => {
      const accessToken = res.getIdToken()
        .getJwtToken()
      const jwt = `Bearer ${accessToken}`
      return jwt
    })
  // Return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token || '',
    },
  }
})

// Config
const defaultOptions = {
  // You can use `https` for secure connection (recommended in production)
  httpEndpoint,
  // httpLinkOptions: {
  //   headers: {
  //     'x-hasura-admin-secret': 'lkWQzf5RVybtGZfDYBRbxnuke7D8FB1kW41yFSyNqvGo05JIPCJzBjDzKTqWSB5Z',
  //   },
  // },
  // You can use `wss` for secure connection (recommended in production)
  // Use `null` to disable subscriptions
  wsEndpoint: null,
  // wsEndpoint: process.env.VUE_APP_GRAPHQL_WS || 'ws://vf0aew9p7l.execute-api.ap-south-1.amazonaws.com/dev/graphql',
  // LocalStorage token
  tokenName: AUTH_TOKEN,
  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,
  // Use websockets for everything (no HTTP)
  // You need to pass a `wsEndpoint` for this to work
  websocketsOnly: false,
  // Is being rendered on the server?
  ssr: false,

  // Override default apollo link
  // note: don't override httpLink here, specify httpLink options in the
  // httpLinkOptions property of defaultOptions.
  link: authLink,
  // link: new HttpLink({
  //   uri: httpEndpoint,
  //   headers: {
  //     'x-hasura-admin-secret': 'lkWQzf5RVybtGZfDYBRbxnuke7D8FB1kW41yFSyNqvGo05JIPCJzBjDzKTqWSB5Z',
  //   },
  // }),

  // Override default cache
  cache: new InMemoryCache({ addTypename: false }),

  // Override the way the Authorization header is set
  // getAuth: (tokenName) => ...

  // Additional ApolloClient options
  // apollo: { ... }

  // Client local data (see apollo-link-state)
  // clientState: { resolvers: { ... }, defaults: { ... } }
}

export const newApolloClient = createApolloClient({
  ...defaultOptions,
}).apolloClient

// Call this in the Vue app file
export function createProvider(options = {}) {
  // Create apollo client
  const {
    apolloClient,
    wsClient,
  } = createApolloClient({
    ...defaultOptions,
    ...options,
  })
  apolloClient.wsClient = wsClient

  // Create vue apollo provider
  return new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        // fetchPolicy: 'cache-and-network',
      },
    },
    errorHandler(error) {
      return error
      // eslint-disable-next-line no-console
    },
  })
}

// // Manually call this when user log in
// export async function onLogin(apolloClient, token) {
//   if (typeof localStorage !== 'undefined' && token) {
//     localStorage.setItem(AUTH_TOKEN, token)
//   }
//   if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
//   try {
//     await apolloClient.resetStore()
//   } catch (e) {
//     // eslint-disable-next-line no-console
//   }
// }
//
// // Manually call this when user log out
// export async function onLogout(apolloClient) {
//   if (typeof localStorage !== 'undefined') {
//     localStorage.removeItem(AUTH_TOKEN)
//   }
//   if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
//   try {
//     await apolloClient.resetStore()
//   } catch (e) {
//     // eslint-disable-next-line no-console
//   }
// }
