export default [
  {
    path: '/teams/manage',
    name: 'manage-teams',
    component: () => import('@/views/teams/ManageTeams.vue'),
    meta: {
      pageTitle: 'Manage Team',
      breadcrumb: [
        {
          text: 'Teams',
        },
        {
          text: 'Manage Team',
          active: true,
        },
      ],
    },
  },
  {
    path: '/teams/add',
    name: 'add-member',
    component: () => import('@/views/teams/AddMember.vue'),
    meta: {
      pageTitle: 'Add Team Member',
      breadcrumb: [
        {
          text: 'Teams',
        },
        {
          text: 'Add Team Member',
          active: true,
        },
      ],
    },
  },
  {
    path: '/teams/add/role',
    name: 'add-role',
    component: () => import('@/views/teams/AddRole.vue'),
    meta: {
      pageTitle: 'Add Role',
      breadcrumb: [
        {
          text: 'Role',
        },
        {
          text: 'Add Role',
          active: true,
        },
      ],
    },
  },
  {
    path: '/teams/manager',
    name: 'team-manager',
    component: () => import('@/views/teams/manageteam/teamManager.vue'),
    meta: {
      pageTitle: 'Team Manager',
      breadcrumb: [
        {
          text: 'Team Manager',
        },
        {
          text: 'Team Manager',
          active: true,
        },
      ],
    },
  },
  {
    path: '/teams/member',
    name: 'view-member',
    component: () => import('@/views/teams/manageteam/viewMember.vue'),
    meta: {
      pageTitle: 'View Member',
      breadcrumb: [
        {
          text: 'View Member',
        },
        {
          text: 'View Member',
          active: true,
        },
      ],
    },
  },
]
