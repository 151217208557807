import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
  tableBasic: [{
    id: 1, title: 'Accel', program: 'Angel', status: 'Suzanne', nextStage: 'Stone',
  }, {
    id: 2, title: 'Egypto', program: 'Sherri', status: 'Valentine', nextStage: 'Goff',
  }, {
    id: 3, title: 'Greeker', program: 'Josie', status: 'Mathews', nextStage: 'Pena',
  }, {
    id: 4, title: 'Acumentor', program: 'Byers', status: 'Irene', nextStage: 'Doris',
  }, {
    id: 5, title: 'Quailcom', program: 'Hampton', status: 'Gamble', nextStage: 'Wright',
  }, {
    id: 6, title: 'Multron', program: 'Mcintosh', status: 'Clarissa', nextStage: 'Sue',
  }, {
    id: 7, title: 'Zillatide', program: 'Alta', status: 'Gilbert', nextStage: 'Coffey',
  }, {
    id: 8, title: 'Vendblend', program: 'Margarita', status: 'Meyers', nextStage: 'Violet',
  }, {
    id: 9, title: 'Myopium', program: 'Fanny', status: 'Jessica', nextStage: 'Matthews',
  }, {
    id: 10, title: 'Quonk', program: 'Joseph', status: 'Beck', nextStage: 'Karyn',
  }]
  ,
  /* eslint-disable global-require */
}

mock.onGet('/onboardings').reply(() => [200, data.tableBasic])
