export default [
  {
    path: '/mentors',
    name: 'manage-mentors',
    component: () => import('@/views/incubator-portal/mentors/pages/ManageMentors.vue'),
    meta: {
      pageTitle: 'Manage Mentors',
      breadcrumb: [
        {
          text: 'Mentors',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentors/add',
    name: 'add-mentors',
    component: () => import('@/views/incubator-portal/mentors/pages/AddMentors.vue'),
    meta: {
      pageTitle: 'Add Member',
      breadcrumb: [
        {
          text: 'Mentors',
          to: { name: 'manage-mentors' },
        },
        {
          text: 'Add Member',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentors/:id',
    name: 'view-mentors',
    component: () => import('@/views/incubator-portal/mentors/pages/AddMentors.vue'),
    meta: {
      pageTitle: 'Update Mentor',
      breadcrumb: [
        {
          text: 'Mentors',
          to: { name: 'manage-mentors' },
        },
        {
          text: 'Add Mentor',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentors/:id/manage',
    name: 'mentor-manager',
    component: () => import('@/views/incubator-portal/mentors/pages/mentor-manager/index.vue'),
    meta: {
      pageTitle: 'Mentor Manager',
      breadcrumb: [
        {
          text: 'Mentors',
          to: { name: 'manage-mentors' },
        },
        {
          text: 'Mentor Manager',
          active: true,
        },
      ],
    },
  },
]
