import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
  tableBasic: [{
    id: 1, title: 'Zilencio', status: 'Meadows', participants: 'Alvarado', partners: 'Earwax', progress: 61,
  }, {
    id: 2, title: 'Toyletry', status: 'Selma', participants: 'Murray', partners: 'Pasturia', progress: 59,
  }, {
    id: 3, title: 'Kidstock', status: 'Letitia', participants: 'Roberson', partners: 'Exoteric', progress: 68,
  }, {
    id: 4, title: 'Deviltoe', status: 'Allison', participants: 'Watson', partners: 'Elentrix', progress: 53,
  }, {
    id: 5, title: 'Billmed', status: 'Franks', participants: 'Rosalinda', partners: 'Genmy', progress: 68,
  }, {
    id: 6, title: 'Everest', status: 'Dejesus', participants: 'Horne', partners: 'Miraclis', progress: 67,
  }, {
    id: 7, title: 'Olympix', status: 'Osborne', participants: 'Harrison', partners: 'Elemantra', progress: 59,
  }, {
    id: 8, title: 'Delphide', status: 'Sharlene', participants: 'Castillo', partners: 'Zolarity', progress: 66,
  }, {
    id: 9, title: 'Velos', status: 'Singleton', participants: 'Agnes', partners: 'Magneato', progress: 57,
  }, {
    id: 10, title: 'Moltonic', status: 'Eleanor', participants: 'Gilbert', partners: 'Fibrodyne', progress: 66,
  }]
  ,
  /* eslint-disable global-require */
}

mock.onGet('/programs').reply(() => [200, data.tableBasic])
