export default [
  {
    path: '/incubator-portal/details',
    name: 'manage-incubator',
    component: () => import('@/views/incubator-portal/ManageIncubator.vue'),
    meta: {
      pageTitle: 'Incubator Details',
      breadcrumb: [
        {
          text: 'My Incubator',
          active: true,
        },
      ],
    },
  },
  {
    path: '/incubator-portal/requests',
    name: 'all-requests',
    component: () => import('@/views/incubator-portal/ManageRequests.vue'),
    meta: {
      pageTitle: 'All Requests',
      breadcrumb: [
        {
          text: 'All Requests',
          active: true,
        },
      ],
    },
  },
  {
    path: '/incubator-portal/feedbacks',
    name: 'all-feedbacks',
    component: () => import('@/views/incubator-portal/ManageFeedbacks.vue'),
    meta: {
      pageTitle: 'All Feedbacks',
      breadcrumb: [
        {
          text: 'All Feedbacks',
          active: true,
        },
      ],
    },
  },
  {
    path: '/incubator-portal/growth',
    name: 'manage-growth',
    component: () => import('@/views/incubator-portal/ManageGrowth.vue'),
    meta: {
      pageTitle: 'Manage Growth',
      breadcrumb: [
        {
          text: 'Manage Growth',
          active: true,
        },
      ],
    },
  },
]
