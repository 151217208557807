export default [
  {
    path: '/mentor-portal/invitations',
    name: 'mentor-invitations',
    component: () => import('@/views/mentor-portal/invitations/Invitation.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Invitations',
      breadcrumb: [
        {
          text: 'Partner',
        },
        {
          text: 'Invitations',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentor-portal/invitations/details/:pid/:apid/:aid?',
    name: 'mentor-application-form',
    component: () => import('@/views/incubator-portal/programs/pages/startups/program-manager/application-manager/ApplicationQuestion.vue'),
    meta: {
      pageTitle: 'Application Questions',
    },
  },
]
