import applications from './applications'
import applynow from './apply-now'
import programs from './programs'

const data = [...applynow, ...programs, ...applications, {
  path: '/startup-portal/',
  name: 'startup-portal-startups',
  component: () => import('@/views/startup-portal/pages/ManageStartups.vue'),
  meta: {
    pageTitle: 'Manage Startups',
    breadcrumb: [
      {
        text: 'Startups',
        active: true,
      },
    ],
  },
}, {
  path: '/startup-portal/:id?/details',
  name: 'startup-portal-details',
  component: () => import('@/views/startup-portal/pages/AddStartups.vue'),
  meta: {
    pageTitle: 'Startup Details',
    breadcrumb: [
      {
        text: 'Startups',
        to: { name: 'startup-portal-startups' },
      },
      {
        text: 'Startup Details',
        active: true,
      },
    ],
  },
},
// List Incubator
{
  path: '/startup-portal/incubators',
  name: 'startup-incubators',
  component: () => import('@/views/startup-portal/pages/incubators/ManageIncubators.vue'),
  meta: {
    pageTitle: 'Manage Incubator',
    breadcrumb: [
      {
        text: 'Startup',
      },
      {
        text: 'Incubators',
        active: true,
      },
    ],
  },
},
// Incubator Manager
{
  path: '/startup-portal/incubator/:id/manage',
  name: 'startup-incubator-manager',
  component: () => import('@/views/startup-portal/pages/incubators/incubator-manager/index.vue'),
  meta: {
    pageTitle: 'Manage Incubator',
    breadcrumb: [
      {
        text: 'Startup',
      },
      {
        text: 'Incubator Manager',
        active: true,
      },
    ],
  },
}]

export default data
