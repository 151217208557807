import applications from './applications'
import applynow from './apply-now'
import programs from './programs'

const data = [...applynow, ...programs, ...applications, {
  path: '/student-portal/',
  name: 'student-portal-students',
  component: () => import('@/views/student-portal/pages/ManageStudents.vue'),
  meta: {
    pageTitle: 'Manage students',
    breadcrumb: [
      {
        text: 'students',
        active: true,
      },
    ],
  },
}, {
  path: '/student-portal/:id?/details',
  name: 'student-portal-details',
  component: () => import('@/views/student-portal/pages/AddStudents.vue'),
  meta: {
    pageTitle: 'student Details',
    breadcrumb: [
      {
        text: 'students',
        to: { name: 'student-portal-students' },
      },
      {
        text: 'student Details',
        active: true,
      },
    ],
  },
},
// List Incubator
{
  path: '/student-portal/incubators',
  name: 'student-incubators',
  component: () => import('@/views/student-portal/pages/incubators/ManageIncubators.vue'),
  meta: {
    pageTitle: 'Manage Incubator',
    breadcrumb: [
      {
        text: 'Student',
      },
      {
        text: 'Incubators',
        active: true,
      },
    ],
  },
},
// Incubator Manager
{
  path: '/student-portal/incubator/:id/manage',
  name: 'student-incubator-manager',
  component: () => import('@/views/student-portal/pages/incubators/incubator-manager/index.vue'),
  meta: {
    pageTitle: 'Manage Incubator',
    breadcrumb: [
      {
        text: 'Student',
      },
      {
        text: 'Incubator Manager',
        active: true,
      },
    ],
  },
}]

export default data
