export default [
  {
    path: '/student-portal/programs',
    name: 'student-program',
    component: () => import('@/views/student-portal/pages/programs/Programs.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Programs',
      breadcrumb: [
        {
          text: 'Student',
        },
        {
          text: 'Programs',
          active: true,
        },
      ],
    },
  },
  {
    path: '/student-portal/programs/:id',
    name: 'student-view-programs',
    component: () => import('@/views/incubator-portal/programs/pages/common/ViewPrograms.vue'),
    meta: {
      pageTitle: 'View Program',
      breadcrumb: [
        {
          text: 'Programs',
        },
        {
          text: 'View Program',
          active: true,
        },
      ],
    },
  },
  {
    path: '/student-portal/programs/select/:id',
    name: 'program-enter',
    component: () => import('@/views/student-portal/pages/programs/SelectOrganisation.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Select Organisation',
      breadcrumb: [
        {
          text: 'Student',
        },
        {
          text: 'Programs',
        },
        {
          text: 'Select Organisation',
          active: true,
        },
      ],
    },
  },
  {
    path: '/student-portal/programs/enter/:id/:aid',
    name: 'student-programs',
    component: () => import('@/views/student-portal/pages/programs/enter/index.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Program',
      breadcrumb: [
        {
          text: 'Programs',
          active: true,
        },
      ],
    },
  },
  {
    path: '/student-portal/programs/enter/:id/:aid/:prid',
    name: 'progress-review',
    component: () => import('@/views/student-portal/pages/programs/enter/ProgressReview.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Progress Review',
      breadcrumb: [
        {
          text: 'Programs',
        },
        {
          text: 'Progress Review',
          active: true,
        },
      ],
    },
  },
  {
    path: '/student-portal/programs/milestone/:id/:aid/:mid',
    name: 'student-milestone-review',
    component: () => import('@/views/student-portal/pages/programs/enter/MilestoneReview.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Milestone Reviews',
      breadcrumb: [
        {
          text: 'Programs',
        },
        {
          text: 'Milestone',
        },
        {
          text: 'Milestone Reviews',
          active: true,
        },
      ],
    },
  },
  {
    path: '/student-portal/programs/progress/details/:id',
    name: 'program-progress-details',
    component: () => import('@/views/student-portal/pages/programs/enter/ProgressDetails.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Program Process',
      breadcrumb: [
        {
          text: 'Programs',
        },
        {
          text: 'Program Process',
          active: true,
        },
      ],
    },
  },
  {
    path: '/student-portal/programs/review/:pid/:apid/:aid?',
    name: 'student-review-questions',
    component: () => import('@/views/incubator-portal/programs/pages/startups/program-manager/review-manager/ReviewQuestion.vue'),
    meta: {
      pageTitle: 'Review Questions',
    },
  },
  {
    // APID = Review ID, AID = Participant ID, RID = Reviewer/Partner ID
    path: '/student-portal/programs/review/evaluations/:pid/:apid/:aid',
    name: 'student-review-evaluations',
    component: () => import('@/views/incubator-portal/programs/pages/startups/program-manager/review-manager/ViewEvaluation.vue'),
    meta: {
      pageTitle: 'Review Evaluations',
      breadcrumb: [
        {
          text: 'Student',
        },
        {
          text: 'Reviews',
        },
        {
          text: 'Evaluations',
          active: true,
        },
      ],
    },
  },
]
