import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
  tableBasic: [{
    id: 1, name: 'Squish', registeredName: 'Genmom', url: 'flumbo.com', founders: 'Olivia', stage: 'Tanisha',
  }, {
    id: 2, name: 'Poochies', registeredName: 'Plasmos', url: 'rodeology.com', founders: 'Ball', stage: 'Gloria',
  }, {
    id: 3, name: 'Entroflex', registeredName: 'Mitroc', url: 'eclipsent.com', founders: 'May', stage: 'Lauri',
  }, {
    id: 4, name: 'Combot', registeredName: 'Concility', url: 'zaggles.com', founders: 'Pace', stage: 'Mcconnell',
  }, {
    id: 5, name: 'Dogtown', registeredName: 'Utara', url: 'goko.com', founders: 'Anne', stage: 'Jillian',
  }, {
    id: 6, name: 'Musanpoly', registeredName: 'Locazone', url: 'comtrak.com', founders: 'Yates', stage: 'Green',
  }, {
    id: 7, name: 'Viagreat', registeredName: 'Sensate', url: 'sealoud.com', founders: 'Valenzuela', stage: 'Downs',
  }, {
    id: 8, name: 'Terragen', registeredName: 'Manglo', url: 'hyplex.com', founders: 'Duffy', stage: 'Anita',
  }, {
    id: 9, name: 'Sustenza', registeredName: 'Virxo', url: 'genekom.com', founders: 'Nola', stage: 'Wendi',
  }, {
    id: 10, name: 'Panzent', registeredName: 'Sultraxin', url: 'flotonic.com', founders: 'Franks', stage: 'Tillman',
  }]
  ,
  /* eslint-disable global-require */
}

mock.onGet('/startups').reply(() => [200, data.tableBasic])
