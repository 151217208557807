import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
  tableBasic: [{
    id: 1, name: 'Kara', role: 'Shelton', email: 'mcclain@earbang.com', phone: '(854) 561-3331', supervisor: 'Betsy',
  }, {
    id: 2, name: 'Coleman', role: 'Logan', email: 'riddle@pushcart.com', phone: '(913) 495-2715', supervisor: 'Lois',
  }, {
    id: 3, name: 'Leblanc', role: 'Sharp', email: 'manuela@gonkle.com', phone: '(889) 407-2761', supervisor: 'Browning',
  }, {
    id: 4, name: 'Hester', role: 'Guy', email: 'harriet@entogrok.com', phone: '(902) 400-3094', supervisor: 'Jeannine',
  }, {
    id: 5, name: 'Angelina', role: 'Steele', email: 'corinne@ultrasure.com', phone: '(804) 592-2979', supervisor: 'Angel',
  }, {
    id: 6, name: 'Virginia', role: 'Bradley', email: 'jaclyn@cognicode.com', phone: '(932) 462-3072', supervisor: 'Howe',
  }, {
    id: 7, name: 'Iris', role: 'Vasquez', email: 'isabelle@columella.com', phone: '(965) 516-2609', supervisor: 'Misty',
  }, {
    id: 8, name: 'Steele', role: 'Knowles', email: 'corina@quailcom.com', phone: '(888) 438-3651', supervisor: 'Georgia',
  }, {
    id: 9, name: 'Lisa', role: 'Bowers', email: 'jillian@pasturia.com', phone: '(818) 534-3757', supervisor: 'Valenzuela',
  }, {
    id: 10, name: 'Latoya', role: 'Sweet', email: 'morton@premiant.com', phone: '(818) 402-3079', supervisor: 'Hess',
  }]
  ,
  /* eslint-disable global-require */
}

mock.onGet('/teams').reply(() => [200, data.tableBasic])
