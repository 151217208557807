import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
  tableBasic: [{
    id: 1, name: 'Qualitex', memberType: 'Franklin', onboadingDate: '2/1/2020', upcomingEngagements: 'Beatriz', program: 'Juanita', startups: 'Globoil',
  }, {
    id: 2, name: 'Emoltra', memberType: 'Mcpherson', onboadingDate: '4/10/2020', upcomingEngagements: 'Betty', program: 'Louisa', startups: 'Sybixtex',
  }, {
    id: 3, name: 'Emergent', memberType: 'Mayra', onboadingDate: '25/4/2020', upcomingEngagements: 'Tamra', program: 'Mayer', startups: 'Geostele',
  }, {
    id: 4, name: 'Terragen', memberType: 'Hayden', onboadingDate: '14/9/2020', upcomingEngagements: 'Christie', program: 'Alissa', startups: 'Sportan',
  }, {
    id: 5, name: 'Assistix', memberType: 'Latoya', onboadingDate: '1/1/2020', upcomingEngagements: 'Forbes', program: 'Jeannie', startups: 'Zomboid',
  }, {
    id: 6, name: 'Egypto', memberType: 'Shannon', onboadingDate: '14/10/2020', upcomingEngagements: 'Gina', program: 'Alicia', startups: 'Enormo',
  }, {
    id: 7, name: 'Eplode', memberType: 'English', onboadingDate: '17/10/2020', upcomingEngagements: 'Joy', program: 'Meyer', startups: 'Zillanet',
  }, {
    id: 8, name: 'Exoteric', memberType: 'Middleton', onboadingDate: '13/8/2020', upcomingEngagements: 'Jerry', program: 'Johanna', startups: 'Poochies',
  }, {
    id: 9, name: 'Zuvy', memberType: 'Reese', onboadingDate: '10/6/2020', upcomingEngagements: 'Hattie', program: 'Shawna', startups: 'Fibrodyne',
  }, {
    id: 10, name: 'Prowaste', memberType: 'Ward', onboadingDate: '23/3/2020', upcomingEngagements: 'Margarita', program: 'Kaye', startups: 'Terascape',
  }]
  ,
  /* eslint-disable global-require */
}

mock.onGet('/networks').reply(() => [200, data.tableBasic])
