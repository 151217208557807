import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
  tableBasic: [
    {
      id: 1,
      avatar: require('@/assets/images/avatars/10-small.png'),
      title: "Korrie O'Crevy",
      description: 'kocrevy0@thetimes.co.uk',
      resourceType: 'Limited Resource',
      date: '09/23/2016',
      satisfactionRating: '89',
      utilRatio: '24',
      status: 'Professional',
    },
    {
      id: 2,
      avatar: require('@/assets/images/avatars/1-small.png'),
      title: 'Bailie Coulman',
      description: 'bcoulman1@yolasite.com',
      resourceType: 'Limited Resource',
      date: '05/20/2018',
      satisfactionRating: '63',
      utilRatio: '77',
      status: 'Resigned',
    },
    {
      id: 3,
      avatar: require('@/assets/images/avatars/9-small.png'),
      title: 'Stella Ganderton',
      description: 'sganderton2@tuttocitta.it',
      resourceType: 'Limited Resource',
      date: '03/24/2018',
      satisfactionRating: '07',
      utilRatio: '50',
      status: 'Professional',
    },
    {
      id: 4,
      avatar: require('@/assets/images/avatars/3-small.png'),
      title: 'Dorolice Crossman',
      description: 'dcrossman3@google.co.jp',
      resourceType: 'Limited Resource',
      date: '12/03/2017',
      satisfactionRating: '33',
      utilRatio: '55',
      status: 'Applied',
    },
    {
      id: 5,
      avatar: require('@/assets/images/avatars/4-small.png'),
      title: 'Harmonia Nisius',
      description: 'hnisius4@gnu.org',
      resourceType: 'Individual Resource',
      date: '08/25/2017',
      satisfactionRating: '90',
      utilRatio: '72',
      status: 'Professional',
    },
    {
      id: 6,
      avatar: require('@/assets/images/avatars/5-small.png'),
      title: 'Genevra Honeywood',
      description: 'ghoneywood5@narod.ru',
      resourceType: 'Individual Resource',
      date: '06/01/2017',
      satisfactionRating: '80',
      utilRatio: '89',
      status: 'Current',
    },
    {
      id: 7,
      avatar: require('@/assets/images/avatars/7-small.png'),
      title: 'Eileen Diehn',
      description: 'ediehn6@163.com',
      resourceType: 'Individual Resource',
      date: '10/15/2017',
      satisfactionRating: '99',
      utilRatio: '9',
      status: 'Professional',
    },
    {
      id: 8,
      avatar: require('@/assets/images/avatars/9-small.png'),
      title: 'Richardo Aldren',
      description: 'raldren7@mtv.com',
      resourceType: 'Shared Resource',
      date: '11/05/2016',
      satisfactionRating: '23',
      utilRatio: '23',
      status: 'Rejected',
    },
    {
      id: 9,
      avatar: require('@/assets/images/avatars/2-small.png'),
      title: 'Allyson Moakler',
      description: 'amoakler8@shareasale.com',
      resourceType: 'Shared Resource',
      date: '12/29/2018',
      satisfactionRating: '67',
      utilRatio: '99',
      status: 'Applied',
    },
    {
      id: 10,
      avatar: require('@/assets/images/avatars/6-small.png'),
      title: 'Merline Penhalewick',
      description: 'mpenhalewick9@php.net',
      resourceType: 'Shared Resource',
      date: '04/19/2019',
      satisfactionRating: '93',
      utilRatio: '0',
      status: 'Professional',
    },
  ],
  /* eslint-disable global-require */
}

mock.onGet('/resources').reply(() => [200, data.tableBasic])
