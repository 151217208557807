import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
  tableBasic: [{
    id: 1, startup: 'Klugger', status: 'Frances', dueDilligence: 'Adipisicing sint ullamco consequat nostrud esse minim.', funds: 4321, fundingStrucuture: 'copeland', equityDebt: 34, totalTrench: 2228,
  }, {
    id: 2, startup: 'Quonata', status: 'Pittman', dueDilligence: 'Excepteur Lorem elit incididunt commodo anim laborum aliquip.', funds: 4652, fundingStrucuture: 'sutton', equityDebt: 38, totalTrench: 4660,
  }, {
    id: 3, startup: 'Vortexaco', status: 'Moore', dueDilligence: 'Ad veniam elit laborum fugiat id.', funds: 2088, fundingStrucuture: 'shelia', equityDebt: 9, totalTrench: 2723,
  }, {
    id: 4, startup: 'Micronaut', status: 'Malone', dueDilligence: 'Eu duis ex anim anim eu eiusmod occaecat.', funds: 2374, fundingStrucuture: 'alfreda', equityDebt: 6, totalTrench: 2689,
  }, {
    id: 5, startup: 'Geeknet', status: 'Thornton', dueDilligence: 'Id exercitation velit ullamco esse aliquip.', funds: 1105, fundingStrucuture: 'flossie', equityDebt: 21, totalTrench: 1677,
  }, {
    id: 6, startup: 'Papricut', status: 'Conley', dueDilligence: 'Ipsum cupidatat exercitation eu ipsum id id dolore consectetur commodo qui.', funds: 2900, fundingStrucuture: 'cathryn', equityDebt: 46, totalTrench: 4868,
  }, {
    id: 7, startup: 'Martgo', status: 'Mayra', dueDilligence: 'Officia amet pariatur quis sunt qui est voluptate.', funds: 1866, fundingStrucuture: 'jaime', equityDebt: 40, totalTrench: 3082,
  }, {
    id: 8, startup: 'Zidox', status: 'Imelda', dueDilligence: 'Mollit sint Lorem nisi magna velit pariatur dolore dolore qui id fugiat eiusmod ullamco consectetur.', funds: 2103, fundingStrucuture: 'nona', equityDebt: 43, totalTrench: 3749,
  }, {
    id: 9, startup: 'Uplinx', status: 'Holly', dueDilligence: 'Mollit aliqua fugiat anim qui sit nostrud minim commodo dolore voluptate veniam consequat deserunt consectetur.', funds: 1484, fundingStrucuture: 'deidre', equityDebt: 38, totalTrench: 4079,
  }, {
    id: 10, startup: 'Bedder', status: 'Schwartz', dueDilligence: 'Deserunt ad ex ex cillum et reprehenderit fugiat et aute quis duis id.', funds: 3137, fundingStrucuture: 'castaneda', equityDebt: 27, totalTrench: 2300,
  }]
  ,
  /* eslint-disable global-require */
}

mock.onGet('/fundings').reply(() => [200, data.tableBasic])
