import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
  tableBasic: [{
    id: 1, title: 'Zimmerman', organiser: 'Day', targetAudience: 'Crayne', registrations: 30, price: 648, date: '4/1/2021', capacity: 77,
  }, {
    id: 2, title: 'Wallace', organiser: 'Hurley', targetAudience: 'Calpine', registrations: 49, price: 567, date: '20/2/2021', capacity: 10,
  }, {
    id: 3, title: 'French', organiser: 'Benjamin', targetAudience: 'Orovada', registrations: 57, price: 711, date: '1/1/2021', capacity: 83,
  }, {
    id: 4, title: 'Debora', organiser: 'Alyson', targetAudience: 'Delco', registrations: 83, price: 626, date: '21/5/2021', capacity: 63,
  }, {
    id: 5, title: 'Gwendolyn', organiser: 'Vinson', targetAudience: 'Coalmont', registrations: 53, price: 648, date: '23/1/2021', capacity: 24,
  }, {
    id: 6, title: 'Caroline', organiser: 'Dyer', targetAudience: 'Smeltertown', registrations: 43, price: 786, date: '25/5/2021', capacity: 85,
  }, {
    id: 7, title: 'Meadows', organiser: 'Wilda', targetAudience: 'Sattley', registrations: 86, price: 784, date: '6/4/2021', capacity: 89,
  }, {
    id: 8, title: 'Sabrina', organiser: 'Cornelia', targetAudience: 'Welch', registrations: 12, price: 673, date: '27/4/2021', capacity: 65,
  }, {
    id: 9, title: 'Cherry', organiser: 'Mayra', targetAudience: 'Corinne', registrations: 29, price: 792, date: '27/8/2021', capacity: 96,
  }, {
    id: 10, title: 'Roman', organiser: 'Baxter', targetAudience: 'Allison', registrations: 80, price: 954, date: '23/11/2021', capacity: 42,
  }, {
    id: 11, title: 'Justice', organiser: 'Herman', targetAudience: 'Hegins', registrations: 47, price: 669, date: '16/9/2021', capacity: 70,
  }, {
    id: 12, title: 'Gonzalez', organiser: 'Santana', targetAudience: 'Coloma', registrations: 42, price: 730, date: '12/3/2021', capacity: 93,
  }]
  ,
  /* eslint-disable global-require */
}

mock.onGet('/events').reply(() => [200, data.tableBasic])
