/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  'aws_project_region': 'ap-south-1',
  'aws_cognito_region': 'ap-south-1',
  'aws_user_pools_id': 'ap-south-1_GtBnnBtWZ',
  'aws_user_pools_web_client_id': '42cpa54tnvdv60r4vsee1u3p4h',
  'oauth': {
    'domain': 'starworks-dev.auth.ap-south-1.amazoncognito.com'
  },
  'aws_cognito_username_attributes': [
    'EMAIL'
  ],
  'aws_cognito_social_providers': [],
  'aws_cognito_signup_attributes': [
    'EMAIL'
  ],
  'aws_cognito_mfa_configuration': 'OFF',
  'aws_cognito_mfa_types': [],
  'aws_cognito_password_protection_settings': {
    'passwordPolicyMinLength': 8,
    'passwordPolicyCharacters': []
  },
  'aws_cognito_verification_mechanisms': [
    'EMAIL'
  ]
}

export default awsmobile
