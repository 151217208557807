import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
  tableBasic: [{
    id: 1,
    source: 'Zillacom',
    revenueStream: 'Fisher',
    revenueCredit: 2007,
    recievables: 1524,
    expenseHead: 'Mitzi',
    expenseDebit: 3326,
    expenseUpcoming: 4112,
  }, {
    id: 2,
    source: 'Terrago',
    revenueStream: 'Wilcox',
    revenueCredit: 3300,
    recievables: 3461,
    expenseHead: 'Trina',
    expenseDebit: 1626,
    expenseUpcoming: 2625,
  }, {
    id: 3,
    source: 'Viagrand',
    revenueStream: 'Mcneil',
    revenueCredit: 1372,
    recievables: 1959,
    expenseHead: 'Judy',
    expenseDebit: 2723,
    expenseUpcoming: 2385,
  }, {
    id: 4,
    source: 'Comfirm',
    revenueStream: 'Robyn',
    revenueCredit: 3129,
    recievables: 2632,
    expenseHead: 'Sanchez',
    expenseDebit: 1739,
    expenseUpcoming: 1536,
  }, {
    id: 5,
    source: 'Otherside',
    revenueStream: 'Monica',
    revenueCredit: 1945,
    recievables: 2281,
    expenseHead: 'Elvira',
    expenseDebit: 2688,
    expenseUpcoming: 4208,
  }, {
    id: 6,
    source: 'Bullzone',
    revenueStream: 'Justine',
    revenueCredit: 4606,
    recievables: 2389,
    expenseHead: 'Mallory',
    expenseDebit: 4323,
    expenseUpcoming: 3223,
  }, {
    id: 7,
    source: 'Zedalis',
    revenueStream: 'Richards',
    revenueCredit: 3918,
    recievables: 4690,
    expenseHead: 'Bonner',
    expenseDebit: 3949,
    expenseUpcoming: 4706,
  }, {
    id: 8,
    source: 'Quonata',
    revenueStream: 'Deanna',
    revenueCredit: 4189,
    recievables: 1733,
    expenseHead: 'Lora',
    expenseDebit: 4112,
    expenseUpcoming: 3709,
  }, {
    id: 9,
    source: 'Gadtron',
    revenueStream: 'Hilda',
    revenueCredit: 2144,
    recievables: 4759,
    expenseHead: 'Megan',
    expenseDebit: 1236,
    expenseUpcoming: 2705,
  }, {
    id: 10,
    source: 'Lunchpod',
    revenueStream: 'Sharlene',
    revenueCredit: 3770,
    recievables: 3889,
    expenseHead: 'Hardin',
    expenseDebit: 4741,
    expenseUpcoming: 1131,
  }]
  ,
  /* eslint-disable global-require */
}

mock.onGet('/finances')
  .reply(() => [200, data.tableBasic])
