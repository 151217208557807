export default [
  {
    path: '/student-portal/apply-now',
    name: 'student-apply-now',
    component: () => import('@/views/student-portal/pages/apply-now/ApplyNow.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Apply Now',
      breadcrumb: [
        {
          text: 'Student',
        },
        {
          text: 'Apply Now',
          active: true,
        },
      ],
    },
  },
]
