export default [
  {
    path: '/tickets/manage',
    name: 'manage-tickets',
    component: () => import('@/views/tickets/ManageTickets.vue'),
    meta: {
      pageTitle: 'Manage Tickets',
      breadcrumb: [
        {
          text: 'Tickets',
        },
        {
          text: 'Manage Tickets',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tickets/add',
    name: 'add-tickets',
    component: () => import('@/views/tickets/AddTickets.vue'),
    meta: {
      pageTitle: 'Add Ticket',
      breadcrumb: [
        {
          text: 'Tickets',
        },
        {
          text: 'Add Startup',
          active: true,
        },
      ],
    },
  },
]
