import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
  tableBasic: [{
    id: 1, startup: 'Bedlam', ticketDetails: 'Officia duis commodo incididunt ea proident qui ea.', createdBy: 'Perry', status: 'In Progress', assignedTo: 'Juarez',
  }, {
    id: 2, startup: 'Nikuda', ticketDetails: 'Occaecat dolore reprehenderit aute voluptate non.', createdBy: 'Socorro', status: 'In Progress', assignedTo: 'Lindsay',
  }, {
    id: 3, startup: 'Quotezart', ticketDetails: 'Ullamco eu velit excepteur commodo dolor proident magna.', createdBy: 'Patrick', status: 'In Progress', assignedTo: 'Tyson',
  }, {
    id: 4, startup: 'Zentury', ticketDetails: 'Do cillum velit velit sunt mollit consectetur.', createdBy: 'Fleming', status: 'In Progress', assignedTo: 'Sanders',
  }, {
    id: 5, startup: 'Polarium', ticketDetails: 'Dolore id nulla officia ex magna voluptate labore est laboris id eiusmod minim nulla adipisicing.', createdBy: 'Patty', status: 'In Progress', assignedTo: 'Lee',
  }, {
    id: 6, startup: 'Waterbaby', ticketDetails: 'Ut occaecat reprehenderit labore cillum laboris est et Lorem occaecat ad aliqua commodo officia laboris.', createdBy: 'Lana', status: 'In Progress', assignedTo: 'Amber',
  }, {
    id: 7, startup: 'Lyrichord', ticketDetails: 'Cillum eu deserunt deserunt in in Lorem in velit ullamco irure.', createdBy: 'Fulton', status: 'In Progress', assignedTo: 'Lolita',
  }, {
    id: 8, startup: 'Strozen', ticketDetails: 'Proident eu ipsum ullamco sint consectetur.', createdBy: 'Goff', status: 'In Progress', assignedTo: 'Todd',
  }, {
    id: 9, startup: 'Exosis', ticketDetails: 'Nulla tempor sint enim cupidatat velit Lorem laboris.', createdBy: 'Lina', status: 'In Progress', assignedTo: 'Harmon',
  }, {
    id: 10, startup: 'Magmina', ticketDetails: 'Aliqua qui qui non ad dolor.', createdBy: 'Thelma', status: 'In Progress', assignedTo: 'Sanchez',
  }]
  ,
  /* eslint-disable global-require */
}

mock.onGet('/tickets').reply(() => [200, data.tableBasic])
