export default [
  {
    path: '/resources/manage',
    name: 'manage-resources',
    component: () => import('@/views/resources/ManageResources.vue'),
    meta: {
      pageTitle: 'Manage Resources',
      breadcrumb: [
        {
          text: 'Resources',
        },
        {
          text: 'Manage Resources',
          active: true,
        },
      ],
    },
  },
  {
    path: '/resources/add',
    name: 'add-resources',
    component: () => import('@/views/resources/AddResources.vue'),
    meta: {
      pageTitle: 'Add Resource',
      breadcrumb: [
        {
          text: 'Resources',
        },
        {
          text: 'Add Resource',
          active: true,
        },
      ],
    },
  },
]
