export default [
  {
    path: '/startup-portal/applications',
    name: 'startup-applications',
    component: () => import('@/views/startup-portal/pages/applications/Applications.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Applications',
      breadcrumb: [
        {
          text: 'Startup',
        },
        {
          text: 'Applications',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup-portal/applications/rounds/:id/:aid?/:sid?',
    name: 'application-round',
    component: () => import('@/views/startup-portal/pages/applications/Rounds.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Round Details',
      breadcrumb: [
        {
          text: 'Startup',
        },
        {
          text: 'Application',
        },
        {
          text: 'Round Details',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup-portal/applications/details/:pid/:apid/:aid?',
    name: 'startup-application-form',
    component: () => import('@/views/incubator-portal/programs/pages/startups/program-manager/application-manager/ApplicationQuestion.vue'),
    meta: {
      pageTitle: 'Application Questions',
    },
  },
  {
    path: '/startup-portal/programs/:pid/applications/:apid/applicant/:aid/evaluations/',
    name: 'startup-view-evaluation',
    component: () => import('@/views/incubator-portal/programs/pages/startups/program-manager/application-manager/ViewEvaluation.vue'),
    meta: {
      pageTitle: 'Application Evaluation',
      breadcrumb: [
        {
          text: 'Startup',
        },
        {
          text: 'Applications',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup-portal/application/evaluations/:pid/:apid/:aid',
    name: 'startup-view-evaluation',
    component: () => import('@/views/incubator-portal/programs/pages/startups/program-manager/application-manager/ViewEvaluation.vue'),
    meta: {
      pageTitle: 'Application Evaluation',
      breadcrumb: [
        {
          text: 'Startup',
        },
        {
          text: 'Applications',
          active: true,
        },
      ],
    },
  },
  {
    // PID = Program, APID = Application ID, AID = Applicant ID, RID = Reviewer/Partner ID
    path: '/startup-portal/programs/:pid/applications/:apid/applicant/:aid/partner/:rid',
    name: 'startup-application-score',
    component: () => import('@/views/incubator-portal/programs/pages/startups/program-manager/application-manager/ApplicationScore.vue'),
    meta: {
      pageTitle: 'Score Response',
      breadcrumb: [
        {
          text: 'Startup',
        },
        {
          text: 'Applications',
          active: true,
        },
      ],
    },
  },
]
