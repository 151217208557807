export default [
  {
    path: '/meet',
    name: '/meet',
    component: () => import('@/views/meet/Meet.vue'),
    meta: {
      layout: 'full',
      pageTitle: '',
    },
  },
]
